import React, { FormEvent, useContext, useEffect, useState } from 'react';
import {
    Athlete,
    League, LeagueWeekendResult,
    LeagueWeekendResultAthlete,
    LeagueWeekendResultSelection,
    LeagueWeekendResultUser, LeagueWeekendViewLoaderData, LoadedLeague,
    RankedLeague,
    RankedUser,
    Selection,
    User,
    Weekend
} from './models';
import { ApplicationLink } from './Routing';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ApplicationComponents, Components, Context, SettingsContext, UserContext } from './Context';
import { FantasyModal } from "./TypescriptComponents";
import { Tabs } from "./Tabs";
import Skeleton from "react-loading-skeleton";
import { useRedirectToLoginIfNeeded } from "./Hooks";
import {
    formatNullableRank,
    formatNullableScore,
    formatScore, HTTP_CLIENT,
    HTTP_CLIENT_JQUERY_ADAPTER,
} from "./Util";
import "./scss/leagues.scss";
import { AxiosResponse } from "axios";

const DeleteLeagueButton = (props: LeagueButtonProperties) => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    return <React.Fragment>

        <FantasyModal isOpen={showDialog}
                      close={(accepted => accepted ? handleClick() : setShowDialog(false))}
                      title="Delete league"
                      message="Are you sure you want to delete this league?"/>
        &nbsp;
        <button className="button" onClick={ () => setShowDialog(true) }>Delete League</button>
    </React.Fragment>

    function handleClick() {
        HTTP_CLIENT_JQUERY_ADAPTER.delete({
            url: `/api/leagues/${props.leagueName}`,
            success: () => {
                navigate('/leagues');
            }
        });
        setShowDialog(false);
    }
}

const LeaveLeagueButton = (props: LeaveLeagueButtonProperties) => {

    function handleClick() {
        HTTP_CLIENT_JQUERY_ADAPTER.delete({
            url: `/api/leagues/${encodeURIComponent(props.leagueName)}/members/me`,
            success: () => {
                props.onUserLeaving(props.leagueName);
            }
        });
    }

    return <button className="button" onClick={ handleClick }>Leave</button>
}

const JoinLeagueButton = (props: JoinLeagueButtonProperties) => {

    const [password, setPassword] = useState<string>("");

    function handleClick() {
        HTTP_CLIENT_JQUERY_ADAPTER.post({
            url: `/api/leagues/${encodeURIComponent(props.leagueName)}/members/`,
            data: {
                password: password
            },
            contentType: 'application/json',
            success: () => {
                props.onUserJoining(props.leagueName);
            }
        });
    }

    return <React.Fragment>
        { props.displayPasswordPrompt
            && <input type="password"
                      className='input-group-field'
                      placeholder="Password"
                      onChange={ (event) => setPassword(event.target.value) } /> }
        <div className="input-group-button">
            <button className="button" onClick={ handleClick }>Join</button>
        </div>
    </React.Fragment>
}

interface LeagueButtonProperties {
    leagueName: string;
}

interface LeaveLeagueButtonProperties extends LeagueButtonProperties{
    onUserLeaving(leagueName: string): void;
}

interface JoinLeagueButtonProperties extends LeagueButtonProperties {
    displayPasswordPrompt: boolean;
    onUserJoining(leagueName: string): void;
}

const LeagueWidget = (props: LeagueWidgetProperties) => {

    const { userIsMember, leagueRequiresPassword, userIsAdmin, leagueName, onUserLeaving, onUserJoining } = props;
    return <div className='input-group' style={{'margin': '0.25rem 0'}}>
        { !userIsMember
            && <JoinLeagueButton leagueName={ leagueName }
                                 onUserJoining={ onUserJoining }
                                 displayPasswordPrompt={ leagueRequiresPassword } /> }
        { userIsMember
            && <LeaveLeagueButton leagueName={ leagueName }
                                  onUserLeaving={ onUserLeaving } /> }

        { userIsAdmin
            && <DeleteLeagueButton leagueName={ leagueName } />}
    </div>
}

interface LeagueWidgetProperties {
    userIsMember: boolean;
    leagueRequiresPassword: boolean;
    userIsAdmin: boolean;
    leagueName: string;
    onUserLeaving(username: string): void;
    onUserJoining(username: string): void;
}

const SingleLeagueWidget = (props: SingleLeagueWidgetProps) => {

    const [leagueRequiresPassword, setLeagueRequiresPassword] = useState<boolean | undefined>();
    const [userIsAdmin, setUserIsAdmin] = useState<boolean | undefined>();
    const [userIsMember, setUserIsMember] = useState<boolean | undefined>();
    const context = useContext<Context>(UserContext);

    useEffect(() => {
        setUserIsMember(props.members.some((member) => member.user.user_id === context.loggedInState.user?.user_id));
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/leagues/${props.leagueName}`,
            success: (league: League) => {
                setUserIsAdmin(league.admin_id === context.loggedInState.user?.user.user_name);
                setLeagueRequiresPassword(league.password_protected);
            }
        });
    }, [context.loggedInState.user, props.members, props.leagueName]);

    function onUserLeaving() {
        setUserIsMember(false);
    }

    function onUserJoining() {
        setUserIsMember(true);
    }

    if (leagueRequiresPassword === undefined
        || userIsAdmin === undefined
        || userIsMember === undefined) {
        return <React.Fragment />;
    }

    return <LeagueWidget leagueName={ props.leagueName }
                         onUserJoining={ onUserJoining }
                         onUserLeaving={ onUserLeaving }
                         leagueRequiresPassword={ leagueRequiresPassword }
                         userIsAdmin={ userIsAdmin }
                         userIsMember={ userIsMember } />;
}

const SingleLeaguePage = () => {

    const { league, leagueScore, leagueMembers } = useLoaderData() as LoadedLeague;
    const [members, setMembers] = useState<RankedUser[]>(leagueMembers);
    useRedirectToLoginIfNeeded();

    function userKicked(user: RankedUser) {
        members.splice(members.indexOf(user), 1)
        setMembers(members)
    }

    if(!league || !members.length) {
        return <React.Fragment />;
    }
    let verifiedCheckmark = null;
    if(league.verified) {
        verifiedCheckmark = <input className="verificationcheckmarkbutton" value="&#10003;"
                                   type="submit" disabled={true}/>
    }
    return <div>
        <h2>
            {league.name}{verifiedCheckmark}
        </h2>
        <div className="row columns medium-6">
            <SingleLeagueWidget members={members} leagueName={league.name}/>
        </div>
        <p>Score: { formatScore(leagueScore.score) }</p>
        <div className="row columns">
            <LeagueMembersTable league={league} members={members} memberKicked={userKicked}/>
        </div>
        <LeagueWeekendScore members={members} leagueName={league.name} />
    </div>;
}

const LeagueWeekendScore = (props: LeagueWeekendScoreProps) => {

    const [resultsPerUser, setResultsPerUser] = useState<Map<number, LeagueWeekendResult[]>>(new Map());
    const [resultsPerWeekend, setResultsPerWeekend] = useState<Map<number, LeagueWeekendResult[]>>(new Map());
    const [weekends, setWeekends] = useState<Weekend[]>([]);

    useEffect(() => {
        HTTP_CLIENT.get<Weekend[]>('/api/weekends?season=current')
            .then((response) => {
                const weekends = response.data
                    .filter(weekend => weekend.selections.some(selection => selection.races.some(race => race.scored)))
                    .sort((w1, w2) => w1.event_start.localeCompare(w2.event_start));
                setWeekends(weekends);
            });
        HTTP_CLIENT.get<LeagueWeekendResult[]>(`/api/leagues/${props.leagueName}/members_weekend_scores`)
            .then((response) => {
                const results = response.data;
                const perWeekend = new Map<number, LeagueWeekendResult[]>;
                const perUser = new Map<number, LeagueWeekendResult[]>;
                results.forEach((result) => {
                    let weekendResults = perWeekend.get(result.weekend_id);
                    if(!weekendResults) {
                        weekendResults = [];
                    }
                    weekendResults.push(result);
                    perWeekend.set(result.weekend_id, weekendResults);
                    let userResults = perUser.get(result.user_id);
                    if(!userResults) {
                        userResults = [];
                    }
                    userResults.push(result);
                    perUser.set(result.user_id, userResults);
                });
                setResultsPerUser(perUser);
                setResultsPerWeekend(perWeekend);
            });

    }, [props.members, props.leagueName]);

    if(!weekends.length || !resultsPerUser.size) {
        return <React.Fragment />
    }
    const weekendLinks = weekends.map((weekend) => <th key={weekend.weekend_name}>
        <ApplicationLink class="link"
                         href={`/leagues/${props.leagueName}/weekend/${weekend.weekend_id}`}>
            {weekend.weekend_name}
        </ApplicationLink>
    </th>);

    const scores = props.members.map((member) => {
        return <UserWeekendTableRow
            key={member.user.user_id}
            results={resultsPerUser.get(member.user.user_id) || []}
            user={member.user}
            weekends={weekends}
            weekendScores={resultsPerWeekend}
            members={props.members}
        />
    });

    return <div className="table-scroll">
        <table className="league_table">
            <thead>
                <tr>
                    <th key="TeamHeader">Team</th>
                    {weekendLinks}
                </tr>
            </thead>
            <tbody>
                {scores}
            </tbody>
        </table>
    </div>;
}

const UserWeekendTableRow = ({weekends, results, user, weekendScores, members}: UserWeekendTableRowProps) => {
    const settings = useContext(SettingsContext);
    const scoreMapping = new Map<number, number | undefined>();
    results.forEach(result => scoreMapping.set(result.weekend_id, result.score));

    const userIsIncludedInTeamScore = (weekend: Weekend, userScore: number) => {
        if (!settings?.users_included_in_league_score) {
            return false;
        }
        if (members.length < settings.users_included_in_league_score) {
            return false;
        }
        let scores = weekendScores.get(weekend.weekend_id);
        if (!scores) {
            return false;
        }
        if (scores.length <= settings.users_included_in_league_score) {
            return true;
        }
        let lowestScore = scores
            .sort((a, b) => (b.score || 0) - (a.score || 0))
            [settings.users_included_in_league_score - 1];

        return userScore >= (lowestScore.score || 0);
    }

    let scores = weekends.map((weekend) => {
        let formattedScore;
        let userScore = scoreMapping.get(weekend.weekend_id);
        if (userScore && userIsIncludedInTeamScore(weekend, userScore)) {
            formattedScore = <b>{ formatScore(userScore) }</b>;
        }
        else {
            formattedScore = formatNullableScore(userScore, '-');
        }
        return <td key={ weekend.weekend_id }>
            { formattedScore }
        </td>
    });
    if(scores.length < weekends.length) {
        const filler = weekends.slice(scores.length).map((weekend) => <td key={weekend.weekend_id} />);
        scores = scores.concat(filler);
    }
    return <tr key={user.user_id}>
        <td className="league_td">
            <ApplicationLink href={`/users/${user.user_id}`}>
                {user.team_name}
            </ApplicationLink>
        </td>
        { scores }
    </tr>;
}

interface UserWeekendTableRowProps {
    results: LeagueWeekendResult[];
    weekends: Weekend[];
    user: User;
    weekendScores: Map<number, LeagueWeekendResult[]>;
    members: RankedUser[];
}

interface LeagueWeekendScoreProps {
    members: RankedUser[];
    leagueName: string;
}

const LeagueMembersTable = (props: LeagueMemberTableProps) => {

    const [kickUser, setKickUser] = useState<RankedUser | undefined>();

    const context = useContext<Context>(UserContext);

    function kickFromLeague(user: RankedUser) {
        HTTP_CLIENT_JQUERY_ADAPTER.delete({
            url: `/api/leagues/${encodeURIComponent(props.league.name)}/members/${encodeURIComponent(user.user.user_id)}`,
            success: () => {
                props.memberKicked(user)
            }
        });
    }

    const user = context.loggedInState.user;
    const userIsAdmin = user?.user.user_name === props.league.admin_id;
    return <table id="users" className="users athletes">
        <FantasyModal isOpen={!!kickUser}
                      close={(accepted => accepted ? kickFromLeague(kickUser as RankedUser) : setKickUser(undefined))}
                      title="Kick user"
                      message="The player will not be allowed to rejoin this league. Are you sure you want to remove them?"/>
        <thead>
            <tr>
                <th/>
                <th>Player</th>
                <th>Score</th>
                <th/>
            </tr>
        </thead>
        <tbody>
            {props.members.map(member => <LeagueMemberRow key={member.user.user_id}
                                                               member={member}
                                                               kickFromLeague={() => setKickUser(member)}
                                                               userIsAdmin={userIsAdmin}/>)}
        </tbody>
    </table>;
}

const LeagueMemberRow = (props: LeagueMemberProps) => {
    const {userDisplay} = useContext<Components>(ApplicationComponents);
    const userContext = useContext<Context>(UserContext);
    let deleteButton = null;
    if(props.userIsAdmin && props.member.user.user_id !== userContext.loggedInState.user?.user.user_id) {
        deleteButton = <input onClick={() => props.kickFromLeague(props.member.user_id)}
                              className="button" type="submit" value="Remove Player" />;
    }
    return <tr>
        <td>{formatNullableRank(props.member.rank, '-')}.</td>
        <td>
            {userDisplay(props.member.user)}
        </td>
        <td>{formatNullableScore(props.member.score, '-')}</td>
        <td>{deleteButton}</td>
    </tr>
}

interface LeagueMemberTableProps {
    members: RankedUser[];
    league: League;
    memberKicked: (user: RankedUser) => void;
}

interface LeagueMemberProps {
    member: RankedUser;
    userIsAdmin: boolean;
    kickFromLeague: (username: number) => void;
}

interface SingleLeagueWidgetProps {
    leagueName: string;
    members: RankedUser[];
}

const LeagueTable = ({ leagues, myLeagues, onUserJoining, onUserLeaving, includeTeamChallenge }: LeagueTableProps) => {
    const sortLeagues = (a : RankedLeague, b : RankedLeague) => {
        if (a.rank === b.rank) {
            return 0;
        }

        if (a.rank === null) {
            return 1;
        }

        if (b.rank === null) {
            return -1;
        }

        return a.rank < b.rank ? -1 : 1;
    }

    const [filter, setFilter] = useState<string>('');

    const namesOfMyTeams = myLeagues.map((league) => (league.name));
    leagues.sort(sortLeagues);
    const leaguesToDisplay = leagues.filter((rankedLeague) => (
        rankedLeague.league.name.toLowerCase().startsWith(filter.toLowerCase())
    ));

    return <React.Fragment>
        <input type="text" onChange={ (event) => setFilter(event.target.value) } placeholder="Search for a league"/>
        <table>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>League</th>
                    { includeTeamChallenge && <th>Team Challenge</th> }
                    <th>Score</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                { leaguesToDisplay.map((rankedLeague) => <tr key={ rankedLeague.league.name }>
                    <td>{ formatNullableRank(rankedLeague.rank, '-') }</td>
                    <td>
                        <ApplicationLink href={ `/leagues/${encodeURIComponent(rankedLeague.league.name)}` }>
                            { rankedLeague.league.name }
                        </ApplicationLink>
                    </td>
                    { includeTeamChallenge && (<td>{ rankedLeague.league.verified
                        && <input className="button verificationcheckmarkbutton" value="&#10003;" type="submit" /> }
                    </td>) }
                    <td>{ formatScore(rankedLeague.score) }</td>
                    <td style={{'float': 'right'}}>
                        <LeagueWidget leagueName={ rankedLeague.league.name }
                                      userIsMember={ namesOfMyTeams.indexOf(rankedLeague.league.name) >= 0 }
                                      userIsAdmin={false}
                                      leagueRequiresPassword={ rankedLeague.league.password_protected }
                                      onUserLeaving={ onUserLeaving }
                                      onUserJoining={ onUserJoining } /></td>
                </tr>) }
            </tbody>
        </table>
    </React.Fragment>
}

interface LeagueTableProps {
    leagues: RankedLeague[];
    myLeagues: League[];
    includeTeamChallenge: boolean;
    onUserJoining(username: string): void;
    onUserLeaving(username: string): void;
}

const CreateLeagueForm = (props: CreateLeagueFormProps) => {

    const [showVerifiedCheckbox, setShowVerifiedCheckbox] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>('');
    const [password, setPassword] = React.useState<string | undefined>(undefined);
    const [verified, setVerified] = React.useState<boolean>(false);

    function handleFocus() {
        setShowVerifiedCheckbox(true);
    }
    const navigate = useNavigate();

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        HTTP_CLIENT_JQUERY_ADAPTER.post({
            url: '/api/leagues',
            contentType: 'application/json',
            data: {
                name,
                password,
                verified
            },
            success: (newLeague: League) => {
                navigate(`/leagues/${encodeURIComponent(newLeague.name)}`);
            }
        });

        return false;
    }

    return <form action="#" onFocus={ handleFocus } onSubmit={ handleSubmit }>
        <fieldset className='fieldset'>
            <legend><h3>Create a New League</h3></legend>
            <input name="name"
                   type="text"
                   minLength={3}
                   onChange={ (event) => setName(event.target.value) }
                   placeholder="Enter your league name" value={name} />
            <input name="password"
                   type="text"
                   onChange={ (event) => setPassword(event.target.value) }
                   placeholder="(Optional password)" value={password} />
            { props.includeTeamChallenge && showVerifiedCheckbox
            && <div className="verify_text">
                <input name="verified" type="checkbox"
                       onChange={ (event) => setVerified(event.target.checked)}
                checked={verified}/> &nbsp;
                Check this box to include this league in the Team Challenge Rankings. Limit one participating league per team/club.
            </div>
            }
            <input type="submit" className="button" value="Create league" />
        </fieldset>
    </form>;
}

interface CreateLeagueFormProps {
    includeTeamChallenge: boolean;
}

const LeaguesApp = (props: LeaguesAppProps) => {

    const [allLeagues, setAllLeagues] = useState<RankedLeague[]>([]);
    const [myLeagues, setMyLeagues] = useState<League[]>([]);
    const [teamChallengeStandings, setTeamChallengeStandings] = useState<RankedLeague[]>([]);
    const [visibleTab, setVisibleTab] = useState<"overallScore" | "teamChallenge" | undefined>()
    useRedirectToLoginIfNeeded();
    const navigate = useNavigate();

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/leagues',
            success: (rankedLeagues: RankedLeague[]) => {
                setAllLeagues(rankedLeagues);
            }
        });

        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/me/leagues',
            success: (leagues: League[]) => {
                setMyLeagues(leagues);
            }
        });
        if(props.includeTeamChallenge) {
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: '/api/team_challenge',
                success: (teamChallengeStandings: RankedLeague[]) => {
                    setTeamChallengeStandings(teamChallengeStandings);
                }
            });
        }
    }, [props.includeTeamChallenge]);

    function onUserJoining(leagueName: string) {
        navigate(`/leagues/${encodeURIComponent(leagueName)}`);
    }

    function onUserLeaving(leagueName: string) {
        const newLeagues = myLeagues.filter((league) => (league.name !== leagueName));
        setMyLeagues(newLeagues);
    }

    const myLeagueNames = myLeagues.map((league) => (league.name));

    const tabs = [
        {
            label: "Overall Score",
            id: "overallScore",
            select: () => setVisibleTab("overallScore")
        }
    ]

    if(props.includeTeamChallenge) {
        tabs.push(
            {
                label: "Team Challenge",
                id: "teamChallenge",
                select: () => setVisibleTab("teamChallenge")
            });
    }

    const visibleTabElement = visibleTab === "overallScore" ? <div>
        <LeagueTable myLeagues={ myLeagues }
                     leagues={ allLeagues }
                     onUserJoining={ onUserJoining }
                     onUserLeaving={ onUserLeaving }
                     includeTeamChallenge={ props.includeTeamChallenge } />
    </div> :
    <div>
        <p>
            These are the current standings in the 2018/2019 team challenge. The team challenge only
            includes verified clubs/teams. Scores in the team challenge are calculated differently
            compared to the overall league competition. <b>Read more about the team challenge&nbsp;
            <a href={ '/info/team_challenge' }>here</a>.</b>
        </p>
        <LeagueTable myLeagues={ myLeagues }
                     leagues={ teamChallengeStandings }
                     onUserJoining={ onUserJoining }
                     onUserLeaving={ onUserLeaving }
                     includeTeamChallenge={ props.includeTeamChallenge } />
    </div>

    return <div>
        <h2>My Leagues</h2>
        <LeagueTable myLeagues={ myLeagues }
                     leagues={ allLeagues.filter((membership) => (myLeagueNames.indexOf(membership.league.name) > -1)) }
                     onUserLeaving={ onUserJoining }
                     onUserJoining={ onUserLeaving }
                     includeTeamChallenge={ props.includeTeamChallenge } />

        <CreateLeagueForm includeTeamChallenge={ props.includeTeamChallenge } />
        <Tabs tabs={tabs} selectedId={visibleTab} />
        <div>
            {visibleTabElement}
        </div>
    </div>
}

interface LeaguesAppProps {
    includeTeamChallenge: boolean;
}

const LeagueWeekendView = () => {

    const [selectedTab, setSelectedTab] = useState<{ id: string, renderer: (selectionResults: Map<number, LeagueWeekendResultSelection>, members: RankedUser[], athletes?: Athlete[], weekendResults?: LeagueWeekendResultUser[]) => JSX.Element | undefined } | undefined>();
    const [athletes, setAthletes] = useState<Athlete[] | undefined>();

    const {weekend, leagueMembers, result } = useLoaderData() as LeagueWeekendViewLoaderData;

    const leagueSelectionResults = result.selections.reduce((a: Map<number, LeagueWeekendResultSelection>, b: LeagueWeekendResultSelection) => {
        a.set(b.selection_id, b);
        return a;
    }, new Map<number, LeagueWeekendResultSelection>());
    const leagueWeekendResult = result.users;

    function renderSelection(selection: Selection,
                             results: Map<number, LeagueWeekendResultSelection>,
                             members: RankedUser[],
                             athletes: Athlete[] | undefined) {
        const selectionResults = results.get(selection.selection_id);
        if(athletes && selectionResults) {
            return <LeagueResultTable members={members}
                                      athletes={athletes}
                                      results={selectionResults.users} />;
        }
        return <Skeleton />;
    }

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/athletes'
        }).then((data: Athlete[]) => {
            setAthletes(data);
        })
    }, [])

    const tabs = weekend.selections.map((selection) => {return {
        label: selection.name,
        id: selection.selection_id.toString(),
        select: () => setSelectedTab({
                id: selection.selection_id.toString(),
                renderer: (results, members, athletes ) => renderSelection(selection, results, members, athletes)
            }
        )
    }});

    tabs.unshift({
        label: "Weekend Overall",
        id: "Overall",
        select: () => setSelectedTab({
            id: "Overall",
            renderer: (_, members, athletes, weekendResults ) => {
                if(athletes && weekendResults) {
                    return <LeagueResultTable members={members}
                                              athletes={athletes}
                                              results={weekendResults} />;
                }
                return <Skeleton />;
            }
        })
    })

    return <React.Fragment>
        <Tabs tabs={ tabs } selectedId={ selectedTab?.id }/>
        {selectedTab?.renderer(leagueSelectionResults, leagueMembers, athletes, leagueWeekendResult)}
    </React.Fragment>
}

const LeagueResultTable = ({members, athletes, results}: {members: RankedUser[], athletes: Athlete[],
    results: LeagueWeekendResultUser[]}) => {

    const {userDisplay, flag} = useContext<Components>(ApplicationComponents);
    const sortedAthleteIds = results.reduce((list, elements) => list.concat(elements.athletes),
        [] as LeagueWeekendResultAthlete[])
        .sort((a, b) => b.score - a.score)
        .reduce((list, athlete) => {
            if(!list.includes(athlete.id)) {
                list.push(athlete.id)
            }
            return list;
        }, [] as number[]);

    const athleteMap = athletes.reduce((map, athlete) => map.set(athlete.athlete_id, athlete), new Map<number, Athlete>());

    const sortedMembers = members
        .map((member) => {
            const result = results.find((result) => result.user_id === member.user.user_id);
            if(result) {
                const score = result.athletes.reduce((sum, athlete) => sum + athlete.score, 0) + (result.extra_score || 0);
                return {
                    ...member.user,
                    score,
                    athletes: result.athletes,
                    extra_score: result.extra_score,
                }
            }
            else {
                return {
                    ...member.user,
                    score: undefined,
                    athletes: [],
                    extra_score: undefined
                }
            }
        })
        .sort((m1, m2) => (m2.score || 0) - (m1.score || 0));

    const headers = sortedMembers.map((member) => <th className="team-name" key={"user-" + member.user_id}>
        { userDisplay(member) }
    </th>);

    const rows = sortedAthleteIds.map((athleteId) => {
        const athlete = athleteMap.get(athleteId) as Athlete;
        return <tr key={athleteId}>
            <td><ApplicationLink href={ `/athletes/${athleteId}` }>
                { flag(athlete.country) }
                { athlete.name }
            </ApplicationLink></td>
            {
                sortedMembers.map((member) =>
                    <td className="user-column" key={athleteId + "-" + member.user_id}>
                        {formatNullableScore(member.athletes.find((athlete) => athlete.id === athleteId)?.score, '-')}
                    </td>
                )
            }
        </tr>
    });

    const hasSidebet = results.filter((user) => user.extra_score !== undefined && user.extra_score !== null).length > 0;

    let sidebet = null;

    if(hasSidebet) {
        sidebet = <tr>
            <td><b>Sidebet</b></td>
            {sortedMembers.map((member) => <td className="user-column" key={"sidebet-" + member.user_id}>{formatScore(member.extra_score || 0)}</td>)}
        </tr>
    }

    return <div>
        <div className="table-scroll">
            <table>
                <thead>
                    <tr>
                        <th className="team-name"/>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                    {sidebet}
                </tbody>
                <thead>
                    <tr>
                        <td/>
                        {sortedMembers.map((member) => <td className="user-column" key={"total-" + member.user_id}>{formatScore(member.score || 0)}</td>)}
                    </tr>
                </thead>
            </table>
        </div>
    </div>
}

export { DeleteLeagueButton, LeaveLeagueButton, LeaguesApp, LeagueWidget, SingleLeagueWidget, SingleLeaguePage, LeagueWeekendView };
